import React, { createContext, useContext, ReactNode } from 'react';
import useTheme from './useTheme'; // Adjust the path as needed

interface ThemeContextProps {
  isLightMode: boolean;
  toggleLightMode: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const CustomThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLightMode, toggleLightMode] = useTheme();

  return (
    <ThemeContext.Provider value={{ isLightMode, toggleLightMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useCustomThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useCustomThemeContext must be used within a CustomThemeProvider');
  }
  return context;
};
