import { combineReducers } from 'redux'
import userReducer from './userReducer'
import uiSettingsDataReducer from './uiSettingsReducer'
import beneficiariesDataReducer from './beneficiariesDataReducer'

const rootReducer = combineReducers({
  user: userReducer,
  uiSettings: uiSettingsDataReducer,
  beneficiaries: beneficiariesDataReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
