// store.js
import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "./Reducers";

// Define the middleware array
const middlewares = [thunk];

// Add logger middleware only in development mode
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

// Create the Redux store with middleware and DevTools extension
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);


export default store;
