import { ConfigProvider } from "antd";
import { CustomThemeProvider } from "customHooks/ThemeContext";
import React, { lazy } from "react";
import { ThemeProvider } from "styled-components";
import LoaderGif from "assets/images/LogoLight.svg"
import { Provider } from 'react-redux'
import store from './redux/store'
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify'
import '@mantine/core/styles.css';
import { MantineProvider } from "@mantine/core";

const AppRoutes = lazy(() => import("./routes/Routes"))

function App() {
  return (
    <React.Suspense
      fallback={
        <div
          className="h-screen w-screen bg-dark090 flex items-center justify-center"
        >
          <img src={LoaderGif} className="animate-fade" alt="Loading..." />
        </div>
      }
    >
      <Provider store={store}>
        <CustomThemeProvider>
          <ThemeProvider
            theme={
              {
                primary: "#B01E1E"
              }
            }>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#B01E1E",
                  colorPrimaryActive: "#B01E1E",
                },
              }}
            >
              <MantineProvider>
                <AppRoutes />
                <ToastContainer />
              </MantineProvider>
            </ConfigProvider>
          </ThemeProvider>
        </CustomThemeProvider>
      </Provider>
    </React.Suspense>
  );
}

export default App;
