export const USER_DATA_LOADING = "USER_DATA_LOADING"
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS"
export const USER_DATA_FAILED = "USER_DATA_FAILED"

export const POSITION_DATA_LOADING = "POSITION_DATA_LOADING"
export const POSITION_DATA_SUCCESS = "POSITION_DATA_SUCCESS"
export const POSITION_DATA_FAILED = "POSITION_DATA_FAILED"

export const COMPANY_DATA_LOADING = "COMPANY_DATA_LOADING"
export const COMPANY_DATA_SUCCESS = "COMPANY_DATA_SUCCESS"
export const COMPANY_DATA_FAILED = "COMPANY_DATA_FAILED"

export const SETUP_DATA_LOADING = "SETUP_DATA_LOADING"
export const SETUP_DATA_SUCCESS = "SETUP_DATA_SUCCESS"
export const SETUP_DATA_FAILED = "SETUP_DATA_FAILED"

export const DEPARTMENT_DATA_LOADING = "DEPARTMENT_DATA_LOADING"
export const DEPARTMENT_DATA_SUCCESS = "DEPARTMENT_DATA_SUCCESS"
export const DEPARTMENT_DATA_FAILED = "DEPARTMENT_DATA_FAILED"

export const UI_SETTINGS_DATA_LOADING = "UI_SETTINGS_DATA_LOADING"
export const UI_SETTINGS_DATA_SUCCESS = "UI_SETTINGS_DATA_SUCCESS"
export const UI_SETTINGS_DATA_FAILED = "UI_SETTINGS_DATA_FAILED"

export const BENEFICIARIES_DATA_LOADING = "BENEFICIARIES_DATA_LOADING"
export const BENEFICIARIES_DATA_SUCCESS = "BENEFICIARIES_DATA_SUCCESS"
export const BENEFICIARIES_DATA_FAILED = "BENEFICIARIES_DATA_FAILED"

export const UNIT_DATA_LOADING = "UNIT_DATA_LOADING"
export const UNIT_DATA_SUCCESS = "UNIT_DATA_SUCCESS"
export const UNIT_DATA_FAILED = "UNIT_DATA_FAILED"

export const USER_GROUP_DATA_LOADING = "USER_GROUP_DATA_LOADING"
export const USER_GROUP_DATA_SUCCESS = "USER_GROUP_DATA_SUCCESS"
export const USER_GROUP_DATA_FAILED = "USER_GROUP_DATA_FAILED"

export const async_status = {
  __DEFAULT__: 'async__DEFAULT__',
  __LOADING__: 'async__LOADING__',
  __LOADED__: 'async__LOADED__',
  __FAILED__: 'async__FAILED__',
};


export interface Beneficiary {
  name: string;
  fname: string;
  lname: string;
  city: string;
  address1: string;
  country_id: string;
  beneficiary_id: string;
  gender: string;
  country: string;
  state: string;
  nationality: string;
  postcode: number;
  mobile: string;
  email: string;
  card_number: string;
}

export interface TransactionData {
  trans_ref: string;
  trans_type: string;
  status: string;
  creation_date: string;
  processed_date: string | null;
  originating_country: string;
  destination_country: string;
  source_currency: string;
  source_amount: number;
  dest_currency: string;
  dest_amount: number;
  payment_method: number;
  benef_id: number;
  benef_name: string;
  benef_mobile: string;
  compliance_needed: boolean;
  compliance_checked: boolean;
}

export interface CreateTransactionInterface {
  trans_type: string;
  beneficiary_id: string;
  source_currency: string;
  destination_currency: string;
  amount_type: string;
  amount: number | string;
  payment_method: string;
  service_level: number | string;
}


export interface Actonsnterface {
  type: any,
  payload: {
    status: 'async__DEFAULT__' | 'async__LOADING__' | 'async__LOADED__' | 'async__FAILED__',
    data: any
  }
}