import * as types from '../Actions/types'

const initialState = {
  status: types.async_status.__DEFAULT__,
  data: {},
}

export interface DestinationCountry {
  country_code: string;
  id: string;
  iso_code: string;
  name: string;
}

const uiSettingsDataReducer = (
  state = initialState,
  action: types.Actonsnterface
) => {
  switch (action.type) {
    case types.UI_SETTINGS_DATA_LOADING:
      return {
        ...state,
        status: types.async_status.__LOADING__,
      }
    case types.UI_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        status: types.async_status.__LOADED__,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      }
    case types.UI_SETTINGS_DATA_FAILED:
      return {
        ...state,
        status: types.async_status.__FAILED__,
        data: {},
      }
    default:
      return state
  }
}

export default uiSettingsDataReducer
